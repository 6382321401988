import AuthService from '@/services/auth-service';

const SECOND_DURATION = 1000;
const INTERVAL = 60 * SECOND_DURATION;
const EVENTS = ['mousedown', 'keypress', 'touchstart'];
const MAX_TIME_DIFFERENCE = 2147483647; // MERSENNE_NUMBER(MAXIMUM_32_BIT_SIGNED_INTEGER)

export default class LocalScheme {
  constructor(auth, options) {
    this.$auth = auth;
    this.name = options._name;
    this.activityInterval = null;

    this.options = Object.assign({}, DEFAULTS, options)
  }

  _setToken(token) {
    if (this.options.globalToken) {
      this.$auth.setToken('jwt', token);
      this.$auth.ctx.app.$axios.setHeader('Authorization', token)
    }
  }

  _setRefreshToken(refreshToken) {
    this.$auth.setRefreshToken('jwt', refreshToken);
  }

  _clearToken() {
    if (this.options.globalToken) {
      this.$auth.ctx.app.$axios.setHeader(this.options.tokenName, false);
      this.$auth.ctx.app.$axios.setHeader('Authorization', false);
    }
  }

  _startListenActivity() {
    let wasActive = false;
    for (let i = 0, len = EVENTS.length; i < len; i++) {
      let event = EVENTS[i];
      document.addEventListener(event, () => {
        wasActive = true;
      }, {passive:true});
    }
    this.activityInterval = setInterval(() => {
      if (wasActive) {
        this._updateToken();
        wasActive = false;
      }
    }, INTERVAL);
  }

  _saveExpirationTime(expiration_time) {
    const difference = (expiration_time - parseInt(Math.round(Date.now() / 1000))) * 1000;
    if (difference <= MAX_TIME_DIFFERENCE) {
      const timeoutId = setTimeout(() => {
        this.$auth.logout().then(() => {
          new AuthService(this.$auth).onLogout();
        });
      }, difference);
      localStorage.setItem('intervalId', timeoutId.toString());
    }
  }

  _deleteExpirationTime(needClearCart) {
    const intervalId = localStorage.getItem('intervalId');
    if (intervalId) {
      clearTimeout(parseInt(intervalId));
      localStorage.removeItem('intervalId');
    }
    if (needClearCart) {
      this.$auth.ctx.app.store.commit('cart/clearCart', null, { root: true });
    }
  }

  _updateToken() {
    this.$auth.request({ url: 'session/refresh', method: 'post' }, {}).then((result) => {
      if (!result.need_token_refresh) {
        return;
      }
      if (this.options.tokenRequired) {
        const token = this.options.tokenType ? this.options.tokenType + ' ' + result.token : result.token;
        this.$auth.setToken(this.name, token);
        this._setToken(token);
        this._setRefreshToken(result.refresh_token);
      }
      this._deleteExpirationTime(false);
      this._saveExpirationTime(result.expiration_time);
    });
  }

  _isExpiryDateLessThanNextRefresh() {
    const difference = (this.$auth.user.expiration_time - parseInt(Math.round(Date.now() / 1000))) * 1000;
    return difference < INTERVAL;
  }

  mounted() {
    if (this.options.tokenRequired) {
      const token = this.$auth.syncToken(this.name);
      this._setToken(token);
      const refreshToken = this.$auth.syncRefreshToken(this.name);
      this._setRefreshToken(refreshToken);
    }

    if (process.client && this.$auth.loggedIn) {
      this._deleteExpirationTime(false);
      this._saveExpirationTime();
      if (this._isExpiryDateLessThanNextRefresh()) {
        this._updateToken();
      }
      this._startListenActivity();
    }

    return this.$auth.fetchUserOnce();
  }

  async login(data) {
    if (!this.options.endpoints.login) {
      return
    }

    // Ditch any leftover local tokens before attempting to log in
    await this._logoutLocally();
    const result = await this.$auth.request(this.options.endpoints.login, data);

    if (this.options.tokenRequired) {
      const token = this.options.tokenType ? this.options.tokenType + ' ' + result.token : result.token;
      this._setToken(token);
      this._setRefreshToken(result.refresh_token);
    }

    return this.fetchUser();
  }

  async setUserToken(tokenValue) {
    // Ditch any leftover local tokens before attempting to log in
    await this._logoutLocally();

    if (this.options.tokenRequired) {
      const token = this.options.tokenType ? this.options.tokenType + ' ' + tokenValue : tokenValue;

      this.$auth.setToken(this.name, token);
      this._setToken(token);
    }

    return this.fetchUser();
  }

  async fetchUser(endpoint) {
    // Token is required but not available
    if (this.options.tokenRequired && !this.$auth.getToken(this.name)) {
      return;
    }

    // User endpoint is disabled.
    if (!this.options.endpoints.user) {
      this.$auth.setUser({});
      return;
    }

    // Try to fetch user and then set
    const user = await this.$auth.requestWith(this.name, endpoint, this.options.endpoints.user);
    this.$auth.setUser(user);
    if (process.client) {
      this._startListenActivity();
      this._saveExpirationTime(user.expiration_time);
    }
  }

  async logout(endpoint) {
    // Only connect to logout endpoint if it's configured
    if (this.options.endpoints.logout) {
      await this.$auth
        .requestWith(this.name, endpoint, this.options.endpoints.logout)
        .catch(() => { });
    }
    this._deleteExpirationTime(true);
    clearInterval(this.activityInterval);

    // But logout locally regardless
    return this._logoutLocally().then(() => {
      window.location.reload();
    });
  }

  async _logoutLocally() {
    if (this.options.tokenRequired) {
      this._clearToken();
    }

    return this.$auth.reset();
  }
}

const DEFAULTS = {
  tokenRequired: true,
  globalToken: true,
};
