import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7c127f50 = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _168b6cb3 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _bcb3b7a2 = () => interopDefault(import('../pages/location-currency.vue' /* webpackChunkName: "pages/location-currency" */))
const _2f13987e = () => interopDefault(import('../pages/manufacturer/index.vue' /* webpackChunkName: "pages/manufacturer/index" */))
const _4bb7511c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _6cfe81d6 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _c0ce4c34 = () => interopDefault(import('../pages/checkout/delivery/index.vue' /* webpackChunkName: "pages/checkout/delivery/index" */))
const _d1c76daa = () => interopDefault(import('../pages/checkout/overview/index.vue' /* webpackChunkName: "pages/checkout/overview/index" */))
const _7b7d9bb8 = () => interopDefault(import('../pages/checkout/status/index.vue' /* webpackChunkName: "pages/checkout/status/index" */))
const _1af37c90 = () => interopDefault(import('../pages/profile/orders/index.vue' /* webpackChunkName: "pages/profile/orders/index" */))
const _55e5ff55 = () => interopDefault(import('../pages/system/password-protection.vue' /* webpackChunkName: "pages/system/password-protection" */))
const _6b8c8f30 = () => interopDefault(import('../pages/partner/idea-yacht/search/index.vue' /* webpackChunkName: "pages/partner/idea-yacht/search/index" */))
const _2dadf5e6 = () => interopDefault(import('../pages/user/email-confirmation/_code.vue' /* webpackChunkName: "pages/user/email-confirmation/_code" */))
const _bceea9e4 = () => interopDefault(import('../pages/user/forgot-password/_token.vue' /* webpackChunkName: "pages/user/forgot-password/_token" */))
const _7a5bde08 = () => interopDefault(import('../pages/brand/_slug.vue' /* webpackChunkName: "pages/brand/_slug" */))
const _2979409a = () => interopDefault(import('../pages/catalog/_slug.vue' /* webpackChunkName: "pages/catalog/_slug" */))
const _2d5cf736 = () => interopDefault(import('../pages/manufacturer/_slug.vue' /* webpackChunkName: "pages/manufacturer/_slug" */))
const _64a75274 = () => interopDefault(import('../pages/page/_slug.vue' /* webpackChunkName: "pages/page/_slug" */))
const _9203cc20 = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _74dc3238 = () => interopDefault(import('../pages/seller/_slug.vue' /* webpackChunkName: "pages/seller/_slug" */))
const _2aee88c6 = () => interopDefault(import('../pages/port/_slug/_category_slug.vue' /* webpackChunkName: "pages/port/_slug/_category_slug" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/brand",
    component: _7c127f50,
    name: "brand"
  }, {
    path: "/checkout",
    component: _168b6cb3,
    name: "checkout"
  }, {
    path: "/location-currency",
    component: _bcb3b7a2,
    name: "location-currency"
  }, {
    path: "/manufacturer",
    component: _2f13987e,
    name: "manufacturer"
  }, {
    path: "/profile",
    component: _4bb7511c,
    name: "profile"
  }, {
    path: "/search",
    component: _6cfe81d6,
    name: "search"
  }, {
    path: "/checkout/delivery",
    component: _c0ce4c34,
    name: "checkout-delivery"
  }, {
    path: "/checkout/overview",
    component: _d1c76daa,
    name: "checkout-overview"
  }, {
    path: "/checkout/status",
    component: _7b7d9bb8,
    name: "checkout-status"
  }, {
    path: "/profile/orders",
    component: _1af37c90,
    name: "profile-orders"
  }, {
    path: "/system/password-protection",
    component: _55e5ff55,
    name: "system-password-protection"
  }, {
    path: "/partner/idea-yacht/search",
    component: _6b8c8f30,
    name: "partner-idea-yacht-search"
  }, {
    path: "/user/email-confirmation/:code?",
    component: _2dadf5e6,
    name: "user-email-confirmation-code"
  }, {
    path: "/user/forgot-password/:token?",
    component: _bceea9e4,
    name: "user-forgot-password-token"
  }, {
    path: "/brand/:slug",
    component: _7a5bde08,
    name: "brand-slug"
  }, {
    path: "/catalog/:slug?",
    component: _2979409a,
    name: "catalog-slug"
  }, {
    path: "/manufacturer/:slug",
    component: _2d5cf736,
    name: "manufacturer-slug"
  }, {
    path: "/page/:slug?",
    component: _64a75274,
    name: "page-slug"
  }, {
    path: "/product/:slug?",
    component: _9203cc20,
    name: "product-slug"
  }, {
    path: "/seller/:slug?",
    component: _74dc3238,
    name: "seller-slug"
  }, {
    path: "/port/:slug?/:category_slug?",
    component: _2aee88c6,
    name: "port-slug-category_slug"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/port-:port_slug/brand",
    component: _7c127f50,
    name: "brand-with-port"
  }, {
    path: "/port-:port_slug/checkout",
    component: _168b6cb3,
    name: "checkout-with-port"
  }, {
    path: "/port-:port_slug/location-currency",
    component: _bcb3b7a2,
    name: "location-currency-with-port"
  }, {
    path: "/port-:port_slug/manufacturer",
    component: _2f13987e,
    name: "manufacturer-with-port"
  }, {
    path: "/port-:port_slug/profile",
    component: _4bb7511c,
    name: "profile-with-port"
  }, {
    path: "/port-:port_slug/search",
    component: _6cfe81d6,
    name: "search-with-port"
  }, {
    path: "/port-:port_slug/checkout/delivery",
    component: _c0ce4c34,
    name: "checkout-delivery-with-port"
  }, {
    path: "/port-:port_slug/checkout/overview",
    component: _d1c76daa,
    name: "checkout-overview-with-port"
  }, {
    path: "/port-:port_slug/checkout/status",
    component: _7b7d9bb8,
    name: "checkout-status-with-port"
  }, {
    path: "/port-:port_slug/profile/orders",
    component: _1af37c90,
    name: "profile-orders-with-port"
  }, {
    path: "/port-:port_slug/system/password-protection",
    component: _55e5ff55,
    name: "system-password-protection-with-port"
  }, {
    path: "/port-:port_slug/partner/idea-yacht/search",
    component: _6b8c8f30,
    name: "partner-idea-yacht-search-with-port"
  }, {
    path: "/port-:port_slug/user/email-confirmation/:code?",
    component: _2dadf5e6,
    name: "user-email-confirmation-code-with-port"
  }, {
    path: "/port-:port_slug/user/forgot-password/:token?",
    component: _bceea9e4,
    name: "user-forgot-password-token-with-port"
  }, {
    path: "/port-:port_slug/brand/:slug",
    component: _7a5bde08,
    name: "brand-slug-with-port"
  }, {
    path: "/port-:port_slug/catalog/:slug?",
    component: _2979409a,
    name: "catalog-slug-with-port"
  }, {
    path: "/port-:port_slug/manufacturer/:slug",
    component: _2d5cf736,
    name: "manufacturer-slug-with-port"
  }, {
    path: "/port-:port_slug/page/:slug?",
    component: _64a75274,
    name: "page-slug-with-port"
  }, {
    path: "/port-:port_slug/product/:slug?",
    component: _9203cc20,
    name: "product-slug-with-port"
  }, {
    path: "/port-:port_slug/seller/:slug?",
    component: _74dc3238,
    name: "seller-slug-with-port"
  }, {
    path: "/port-:port_slug/port/:slug?/:category_slug?",
    component: _2aee88c6,
    name: "port-slug-category_slug-with-port"
  }, {
    path: "/port-:port_slug/",
    component: _2dfb1658,
    name: "index-with-port"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
