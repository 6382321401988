import onLoad from "~/helpers/onLoad";

export default ({ app }, inject) => {
    onLoad(() => {
        let d = document;
        let s = d.createElement("script");
        s.src = "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        s.defer = true;
        d.head.appendChild(s);
    });
};